.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 2px;
  }
}

.popup-modal {
  .ant-modal-body {
    padding-bottom: 24px;
  }
}