.ant-menu-horizontal .ant-menu-item svg {
  vertical-align: middle;
  margin-top: -2px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-menu-submenu-arrow {
  right: 0;
}