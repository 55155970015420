.ant-menu-item {
    display: flex;
    align-items: center;
}

.ant-menu-submenu-title {
    display: block;
    align-items: center;
}

.ant-menu-item-only-child {
    padding-left: 56px;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
    text-align: left !important;
}

.ant-menu-vertical .ant-menu-item {
    text-align: center;
}

.ant-menu-submenu-arrow {
    width: 24px;
}

.ant-menu-item-group-title {
    font-weight: @font-weight-bold;
    padding: 12px 24px;
}

.@{class-prefix}-sidebar {

    .ant-menu.ant-menu-inline-collapsed>.ant-menu-item,
    .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
    .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
    .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
        padding: 0 calc(50% - 24px / 2) !important;
    }

    &.ant-layout-sider-collapsed {
        .ant-layout-sider-children>.ant-row:first-child {
            margin-right: 20px !important;
            margin-left: 20px !important;
        }

        .ant-layout-sider-children>.ant-menu {
            margin-top: 14px;
        }

        .ant-menu-item .ant-menu-item-icon,
        .ant-menu-submenu-title .ant-menu-item-icon,
        .ant-menu-item .remix-icon,
        .ant-menu-submenu-title .remix-icon {
            min-width: 24px;
        }
    }

    .ant-layout-sider-children {
        position: sticky;
        top: 0;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .ant-row:nth-child(1) {
            .ant-btn {
                padding: 0;
                overflow: hidden;

                >svg {
                    width: 100%;
                    height: 100%;

                    >g {
                        transform: scale(0.4);
                        transform-origin: center;
                    }
                }
            }
        }

        .ant-menu-root {
            position: relative;
            min-height: calc(100vh - 179px);
            max-height: calc(100vh - 179px);
            flex: 1 1;
            padding-bottom: 16px;
        }
    }
}

.ant-menu.ant-menu-root {
    justify-content: end;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    position: sticky;
    top: 0px;
    margin-top: 7px;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {

    text-align: center;
}

.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .remix-icon {
    margin: 0;
    font-size: 24px;
}

.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon {
    font-size: 24px;
}

.@{class-prefix}-logo {
    height: 40px;
}

.@{class-prefix}-mobile-sidebar {
    display: none;

    .ant-drawer-header {
        border: none;
    }
}

@media @lg {
    .@{class-prefix}-mobile-sidebar {
        display: block;
    }

    .@{class-prefix}-sidebar {
        &.ant-layout-sider {
            display: none;
        }
    }

    .@{class-prefix}-mobile-sidebar {
        .ant-menu.ant-menu-root.ant-menu-inline {
            margin-top: 0;
            position: relative;
            padding-bottom: 24px;
            height: 100%;
        }

        .ant-drawer-body {
            padding: 18px 4px 0;
            display: flex;
            flex-direction: column;
        }
    }

}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% - 20px);
    margin-left: 10px;
    border-radius: 7px;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    top: 8px;
    bottom: 8px;
    right: 5px;
    border-radius: 2px;
    padding: 8px 0;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    padding-left: 24px !important;
}

.ant-menu-item-selected-in-active {
    background: @b-0  !important;
    color: inherit !important;

    a {
        color: inherit !important;
    }

    &:after {
        transform: scaleY(0.0001) !important;
        opacity: 0 !important;
    }
}