@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus&family=Roboto&display=swap');

/* Fonts */
/* #root { */
    /* font-family: 'Roboto', sans-serif; */
/* } */

/* Filter Form */
.filter .ant-form-item {
    margin-bottom: 10px;
}


/* Logo */
.da-logo {
    width: 4em;
    height: 4em;
    margin: 0 0.5em;
}

/* Card */
.ant-card-body {
    padding: 1em;
    /* height: 100%; */
}

/* .ant-card-body>* { */
    /* height: 100%; */
/* } */

/* Form */
.ant-form-item label {
    font-weight: 500;
}

/* Nav Menu (Tabs) */
/* .ant-menu{
    font-size: larger;
    font-weight: 500;
}

.ant-menu-root{
    margin-top: 12px !important;
    height: 4.5em !important;
} */

/* Data Table */

/* create new feilds */
.ant-picker,
.ant-input-number {
    width: 100%;
}

.leftcontent-img {
    width: 50%;
    margin-bottom: 0;
}

.leftcontent-title {
    z-index: 11;
    position: fixed;
    left: 33px;
}

.ant-btn>span>svg {
    margin: 0 5px;
}

.ant-btn {
    background: #f7fafc;
    padding: 0px 14px;
    min-height: 35px;
    border: 0px;
    /* border-color: #f7fafc;
    background: #f7fafc; */
}

.ant-btn:hover {
    border: 1px solid #0010f7;
}

.ant-btn-primary {
    border-color: inherit;
    background: #0010f7;
}

.ant-message-notice-content {
    /* position: absolute; */
    margin-top: 80vh;
    /* display: none; */
}

.ant-form-horizontal .ant-form-item-label {
    display: inline-flex;
    width: 100%;
}

.ant-input[disabled] {
    color: #696969;
}

.ant-layout-header {
    margin: 0 !important;
    z-index: 99 !important;
}

.ant-tag {
    font-size: 1em !important;
    display: flex;
    align-items: center;
    padding: 1px 9px;
    margin: 2px;
}

.ant-modal-confirm-body>span {
    margin-right: 10px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 1em;
    display: inline-block;
}

.ant-modal-confirm-body>span {
    margin-bottom: -6px;
    display: none;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 1.2em;
}

.ant-layout-footer .da-logo {
    width: 40px;
    height: 40px;
}

.ant-layout-footer .da-pb-24,
.ant-layout-footer .da-px-24 {
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.ant-layout-header .da-mobile-sidebar-button .ant-btn {
    padding: 0 !important;
}

.table-edit-icon {
    display: none;
}

.edit-column-row:hover .table-edit-icon {
    display: initial;
}

.text-align-centre {
    text-align: center;
}

.align-center-loading {
    height: 80vh;
    align-content: center;
}
.br-white{
    border: 1px solid #fff;
}
 .tb100 .ant-table table{
    width: 100% !important;
}
.mb10{
 margin-bottom: 10px;
}

.ant-form-horizontal .ant-form-item-control{
    min-width: unset !important;
}